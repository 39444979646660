/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVideoRequest = /* GraphQL */ `
  query GetVideoRequest($id: ID!) {
    getVideoRequest(id: $id) {
      id
      VideoName
      VideoRequester
      filePath
      description
      VideoResults {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoRequestVideoResultsId
    }
  }
`;
export const listVideoRequests = /* GraphQL */ `
  query ListVideoRequests(
    $filter: ModelVideoRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        VideoName
        VideoRequester
        filePath
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoRequestVideoResultsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoRequests = /* GraphQL */ `
  query SyncVideoRequests(
    $filter: ModelVideoRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        VideoName
        VideoRequester
        filePath
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoRequestVideoResultsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoResults = /* GraphQL */ `
  query GetVideoResults($id: ID!) {
    getVideoResults(id: $id) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideoResults = /* GraphQL */ `
  query ListVideoResults(
    $filter: ModelVideoResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoResults = /* GraphQL */ `
  query SyncVideoResults(
    $filter: ModelVideoResultsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoResultsRekognition = /* GraphQL */ `
  query GetVideoResultsRekognition($id: ID!, $Timestamp: Int) {
    getVideoResultsRekognition(id: $id, Timestamp: $Timestamp) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
    }
  }
`;
export const listVideoResultsRekognitions = /* GraphQL */ `
  query ListVideoResultsRekognitions(
    $filter: TableVideoResultsRekognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoResultsRekognitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
      }
      nextToken
    }
  }
`;
export const getVideoResultsRekognitionById = /* GraphQL */ `
  query GetVideoResultsRekognitionById(
    $videoid: String!
    $limit: Int
    $nextToken: String
  ) {
    getVideoResultsRekognitionById(
      videoid: $videoid
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
      }
      nextToken
    }
  }
`;
