/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVideoRequest = /* GraphQL */ `
  mutation CreateVideoRequest(
    $input: CreateVideoRequestInput!
    $condition: ModelVideoRequestConditionInput
  ) {
    createVideoRequest(input: $input, condition: $condition) {
      id
      VideoName
      VideoRequester
      filePath
      description
      VideoResults {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoRequestVideoResultsId
    }
  }
`;
export const updateVideoRequest = /* GraphQL */ `
  mutation UpdateVideoRequest(
    $input: UpdateVideoRequestInput!
    $condition: ModelVideoRequestConditionInput
  ) {
    updateVideoRequest(input: $input, condition: $condition) {
      id
      VideoName
      VideoRequester
      filePath
      description
      VideoResults {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoRequestVideoResultsId
    }
  }
`;
export const deleteVideoRequest = /* GraphQL */ `
  mutation DeleteVideoRequest(
    $input: DeleteVideoRequestInput!
    $condition: ModelVideoRequestConditionInput
  ) {
    deleteVideoRequest(input: $input, condition: $condition) {
      id
      VideoName
      VideoRequester
      filePath
      description
      VideoResults {
        id
        Timestamp
        Codec
        ColorRange
        DurationMillis
        Face
        Format
        FrameHeight
        FrameRate
        FrameWidth
        job_id
        job_tag
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoRequestVideoResultsId
    }
  }
`;
export const createVideoResults = /* GraphQL */ `
  mutation CreateVideoResults(
    $input: CreateVideoResultsInput!
    $condition: ModelVideoResultsConditionInput
  ) {
    createVideoResults(input: $input, condition: $condition) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVideoResults = /* GraphQL */ `
  mutation UpdateVideoResults(
    $input: UpdateVideoResultsInput!
    $condition: ModelVideoResultsConditionInput
  ) {
    updateVideoResults(input: $input, condition: $condition) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVideoResults = /* GraphQL */ `
  mutation DeleteVideoResults(
    $input: DeleteVideoResultsInput!
    $condition: ModelVideoResultsConditionInput
  ) {
    deleteVideoResults(input: $input, condition: $condition) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVideoResultsRekognition = /* GraphQL */ `
  mutation CreateVideoResultsRekognition(
    $input: CreateVideoResultsRekognitionInput!
  ) {
    createVideoResultsRekognition(input: $input) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
    }
  }
`;
export const updateVideoResultsRekognition = /* GraphQL */ `
  mutation UpdateVideoResultsRekognition(
    $input: UpdateVideoResultsRekognitionInput!
  ) {
    updateVideoResultsRekognition(input: $input) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
    }
  }
`;
export const deleteVideoResultsRekognition = /* GraphQL */ `
  mutation DeleteVideoResultsRekognition(
    $input: DeleteVideoResultsRekognitionInput!
  ) {
    deleteVideoResultsRekognition(input: $input) {
      id
      Timestamp
      Codec
      ColorRange
      DurationMillis
      Face
      Format
      FrameHeight
      FrameRate
      FrameWidth
      job_id
      job_tag
      status
    }
  }
`;
