import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function JsonDataDisplay(props) {
    const {jsonData} = props;
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell align="right">ColorRange</TableCell>
                        <TableCell align="right">Face</TableCell>
                        <TableCell align="right">Format</TableCell>
                        <TableCell align="right">FrameHeight</TableCell>
                        <TableCell align="right">FrameRate</TableCell>
                        <TableCell align="right">FrameWidth</TableCell>
                        <TableCell align="right">Codec</TableCell>
                        <TableCell align="right">id</TableCell>
                        <TableCell align="right">job_id</TableCell>
                        <TableCell align="right">job_tag</TableCell>
                        <TableCell align="right">status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jsonData.map((row) => (
                        <TableRow
                            key={row.Timestamp}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.ColorRange}
                            </TableCell>
                            <TableCell align="right">{row.Face}</TableCell>
                            <TableCell align="right">{row.Format}</TableCell>
                            <TableCell align="right">{row.FrameHeight}</TableCell>
                            <TableCell align="right">{row.FrameRate}</TableCell>
                            <TableCell align="right">{row.FrameWidth}</TableCell>
                            <TableCell align="right">{row.Codec}</TableCell>
                            <TableCell align="right">{row.id}</TableCell>
                            <TableCell align="right">{row.job_id}</TableCell>
                            <TableCell align="right">{row.job_tag}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default JsonDataDisplay;

