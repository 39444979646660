import React, {useState} from 'react';


import {v4 as uuid} from 'uuid';

import Amplify, {API, Auth, graphqlOperation, Storage} from 'aws-amplify';

import {IconButton, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {createVideoRequest} from "../../graphql/mutations";


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const AddVideo = ({onUpload}) => {
    const [VideoData, setVideoData] = useState({});
    const [mp4Data, setMp4Data] = useState();
    const [progress, setProgress] = React.useState(0);
    const [uploading, setUploading] = React.useState(false);
    const progressCallback = (progress) => {
        const progressInPercentage = Math.round(
            (progress.loaded / progress.total) * 100
        );
        setProgress(progressInPercentage)
        // console.log(`Progress: ${progressInPercentage}%`);
    };

    const uploadVideo = async () => {
        //Upload the Video
        console.log('VideoData', VideoData);
        setUploading(true)
        const {VideoName, description} = VideoData;
        const user = await Auth.currentAuthenticatedUser()
        let generated_video_id = uuid();
        const {key} = await Storage.put(`${generated_video_id}.mp4`,
            mp4Data,
            {level: 'private', contentType: 'video/mp4', progressCallback});

        const createVideoInput = {
            id: generated_video_id,
            VideoName,
            VideoRequester: user.attributes.email,
            description,
            filePath: key,
        };
        await API.graphql(graphqlOperation(createVideoRequest, {input: createVideoInput}));
        onUpload();
        setUploading(false)
    };

    return (
        <div className="newVideo">
            <TextField
                label="Video Name"
                value={VideoData.VideoName}
                onChange={e => setVideoData({...VideoData, VideoName: e.target.value})}
            />
            <TextField
                label="Description"
                value={VideoData.description}
                onChange={e => setVideoData({...VideoData, description: e.target.value})}
            />
            <input type="file" accept="video/mp4" onChange={e => setMp4Data(e.target.files[0])}/>
            <IconButton onClick={uploadVideo}>
                <CloudUploadIcon/>
            </IconButton>
            {uploading ?
                (
                    <Box sx={{width: '100%'}}>
                    <LinearProgressWithLabel value={progress}/>
                </Box>
                ) : null}
        </div>
    );
};

export default AddVideo;
