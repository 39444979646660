import {Amplify} from 'aws-amplify';

import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import VideoList from "./components/VideoList";
import {BrowserRouter as Router, Routes, Switch, Route, Link, Navigate} from 'react-router-dom';

Amplify.configure(awsExports);
console.log("aa")

function App({signOut, user}) {
    console.log("useruser", user)
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={
                    <>
                        <h1>Hello {user.attributes.email}</h1>
                        <button onClick={signOut}>Sign out</button>
                        <button>
                            <Link to="/rekognition">Use Rekognition</Link>
                        </button>
                    </>
                }/>
                <Route exact path="/rekognition" element={<VideoList/>}/>
            </Routes>
        </Router>
    );
}

export default withAuthenticator(App);