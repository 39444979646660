import React, {useEffect, useState} from 'react';
import {
    getVideoResults,
    getVideoResultsRekognition, getVideoResultsRekognitionById,
    listVideoRequests,
    listVideoResultsRekognitions
} from '../../graphql/queries';

import Amplify, {API, Auth, graphqlOperation, Storage} from 'aws-amplify';
import LoadingButton from '@mui/lab/LoadingButton';

import {Paper, IconButton, TextField, Button} from '@mui/material';
import JsonDataDisplay from '../JsonDataDisplay'
import AddIcon from '@mui/icons-material/Add';
import AddVideo from '../AddVideo';
import {red} from "@mui/material/colors";

const VideoList = () => {
    const [Videos, setVideos] = useState([]);
    const [VideoPlaying, setVideoPlaying] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [showWaitProcess, setshowWaitProcess] = useState(false);
    const [showResults, setShowResults] = useState(true);
    const [showAddVideo, setShowAddNewVideo] = useState(false);
    const [regonitionResults, setRegonitionResults] = useState({});

    useEffect(() => {
        fetchVideosResultsRekognition();
    }, []);

    const toggleVideo = async idx => {
        if (VideoPlaying === idx) {
            setVideoPlaying('');
            setShowResults(true)
            return;
        }

        const VideoFilePath = Videos[idx].filePath;
        const VideoId = Videos[idx].id;
        try {
            const fileAccessURL = await Storage.get(VideoFilePath, {expires: 60});
            const videoRekognitionResults = await fetchVideoByIdResultsRekognition(VideoId)
            console.log('access url', fileAccessURL);
            console.log('videoRekognitionResults', videoRekognitionResults);
            setVideoPlaying(idx);
            setVideoURL(fileAccessURL);
            return;
        } catch (error) {
            console.error('error accessing the file from s3', error);
            setVideoURL('');
            setVideoPlaying('');
        }
    };

    const fetchVideosResultsRekognition = async () => {
        const user = await Auth.currentAuthenticatedUser()
        try {
            const VideoData = await API.graphql(graphqlOperation(listVideoRequests, {
                filter:
                    {
                        VideoRequester: {
                            eq: user.attributes.email
                        }
                    }
            }));
            console.log("VideoData.dataVideoData.dataVideoData.data", VideoData.data)
            const VideoList = VideoData.data.listVideoRequests.items;
            console.log('Video list Requested', VideoList);
            setVideos(VideoList);
        } catch (error) {
            console.log('error on fetching Videos', error);
        }
    };
    const fetchVideoByIdResultsRekognition = async (videoId) => {
        try {
            console.log("Looking for video id: ", videoId)
            const VideoData = await API.graphql(graphqlOperation(getVideoResultsRekognitionById, {
                videoid: videoId
            }));
            console.log("VideoListResultsRekognitionVideoListResultsRekognition", VideoData.data)
            const VideoListResultsRekognition = VideoData.data.getVideoResultsRekognitionById.items;
            console.log('Video list Results Rekognition', VideoListResultsRekognition);
            if (VideoListResultsRekognition.length > 0) {
                setLoading(false)
                setRegonitionResults(VideoListResultsRekognition)
            } else {
                setLoading(false)
                setshowWaitProcess(true)
                setRegonitionResults({})
            }

        } catch (error) {
            console.log('error on fetching Videos', error);
        }
    };

    return (
        <div className="VideoList">
            {Videos.map((Video, idx) => {
                return (
                    <Paper variant="outlined" elevation={2} key={`Video${idx}`}>
                        <div className="VideoCard">
                            <IconButton aria-label="play" onClick={() => toggleVideo(idx)}>
                                <LoadingButton loading={loading} loadingIndicator="Loading..." variant="outlined">
                                    Fetch data
                                </LoadingButton>

                            </IconButton>
                            {showWaitProcess ?
                                <p color={red}> Rekognition still processing your video, please retry in 2
                                    minutes</p> : null}
                            <div>
                                <div className="VideoTitle">{Video.VideoName}</div>
                                <div className="VideoOwner">{Video.VideoRequester}</div>
                                <div className="VideoId">{Video.id}</div>
                            </div>

                            <div className="VideoDescription">{Video.description}</div>
                        </div>
                        {(VideoPlaying === idx && regonitionResults.length > 0) ? (
                            <div className="ourVideoPlayer">
                                {showResults ? <Button variant="text" onClick={() => {
                                        setShowResults(false)
                                    }}>Hide Results</Button>
                                    : <Button variant="text" onClick={() => {
                                        setShowResults(true)
                                    }}>Show Results</Button>}
                                {showResults ? (<JsonDataDisplay
                                    jsonData={regonitionResults}
                                />) : null}
                            </div>
                        ) : null}
                    </Paper>
                );
            })}
            {showAddVideo ? (
                <AddVideo
                    onUpload={() => {
                        setShowAddNewVideo(false);
                        fetchVideosResultsRekognition();
                    }}
                />
            ) : (
                <IconButton onClick={() => setShowAddNewVideo(true)}>
                    <AddIcon/>
                </IconButton>
            )}
        </div>
    );
};

export default VideoList;
